import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/PixelIndex',
    name: 'pixelIndex',
    component: () => import('../views/pixelIndex.vue')
  },
  {
    path: '/ChartIndex',
    name: 'chartIndex',
    component: () => import('../views/chartIndex.vue')
  },
  {
    path: '/YingSi',
    name: 'YingSi',
    component: () => import('../views/yingSi.vue')
  },
  {
    path: '/YongHu',
    name: 'YongHu',
    component: () => import('../views/yongHu.vue')
  },
  {
    path: '/PixelYingSi',
    name: 'pixelYingSi',
    component: () => import('../views/pixelYingSi.vue')
  },
  {
    path: '/PixelYongHu',
    name: 'pixelYongHu',
    component: () => import('../views/pixelYongHu.vue')
  },
  {
    path: '/DailyYingSi',
    name: 'dailyYingSi',
    component: () => import('../views/dailyYingSi.vue')
  },
  {
    path: '/DailyYongHu',
    name: 'dailyYongHu',
    component: () => import('../views/dailyYongHu.vue')
  },
  {
    path: '/BianYingSi',
    name: 'bianYingSi',
    component: () => import('../views/bianYingSi.vue')
  },
  {
    path: '/BianYongHu',
    name: 'bianYongHu',
    component: () => import('../views/bianYongHu.vue')
  },
  {
    path: '/ChartYingSi',
    name: 'chartYingSi',
    component: () => import('../views/chartYingSi.vue')
  },
  {
    path: '/ChartYongHu',
    name: 'chartYongHu',
    component: () => import('../views/chartYongHu.vue')
  },
  {
    path: '/YingSiVIVO',
    name: 'YingSiVIVO',
    component: () => import('../views/yingSiVIVO.vue')
  },
  {
    path: '/YongHuVIVO',
    name: 'YongHuVIVO',
    component: () => import('../views/yongHuVIVO.vue')
  },
  {
    path: '/GeRenXinXi',
    name: 'GeRenXinXi',
    component: () => import('../views/geRenXinXi.vue')
  },
  {
    path: '/PermissionList',
    name: 'PermissionList',
    component: () => import('../views/permissionList.vue')
  },
  {
    path: '/ThreeSdkXinXi',
    name: 'ThreeSdkXinXi',
    component: () => import('../views/threeSdkXinXi.vue')
  },
  {
    path: '/ThreeSdkInfo',
    name: 'ThreeSdkInfo',
    component: () => import('../views/threeSdkInfo.vue')
  },
  {
    path: '/MwmberXieYi',
    name: 'MwmberXieYi',
    component: () => import('../views/memberXieYi.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
